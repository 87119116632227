#obras-faenge{
    background:url('../images/BG-Branco.jpg');
    background-size: cover;
    padding-bottom: 0;

    .container {

        &::before {
            content: "";
            width: 100px;
            height: 170px;
            display: block;
            position: absolute;
            border: 1px solid #84754f;
            border-right: none;
            border-top: none;
            border-radius: 0px 5px 0px 5px;

        }
    }
    h2{
        font-family: $font-default;
        color: #84754f;
        font-size: 3.5em;
        width: 70%;
        font-weight: 100;
        letter-spacing: 0px;
        @include mq("phone-wide",max){
            font-size: 1.5em;
        }
    }
    .gridObras{
        background: url('../images/bg_azul2.jpg');
        background-size: cover;
        padding: 50px;
        color: #fff;


        .grid{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: center;
            font-size: 0.8em;
            text-transform: uppercase;
            .item{
              
                img{
                    max-height: 320px;
                }
                .get-park{
                    height: 320px;
                }
            }
            .content-thumbs{
                img{
                    height: 130px;
                    width: 130px;
                    object-fit: cover;
                }
            }
            &.thumbs3{
                img{
                    height: 130px;
                }
            }
        }
    }
}

.col-obras{
        flex: 0 0 100%;
        max-width: 100%;
        padding-bottom: 3rem;

        &::before {
            content: "";
            width: 25%;
            height: 3rem;
            display: block;
            position: absolute;
            border: 1px solid #84754f;
            border-left: none;
            border-bottom: none;
            border-radius: 0px 5px 0px 5px;
            right: 0;
            margin-right: 10rem;

            @include mq("phone-wide",max){
                width: 50%;
                margin-right: 2rem;
            }
        }
}
.col-fotos1{
    flex: 0 0 30%;
    max-width: 30%;
    text-align: right;

    @include mq("phone-wide",max){
        flex: 0 0 90%;
        max-width: 90%;
    }

    img{
        height: 160px;
        @include mq("phone-wide",max){
            height: auto;
        }
    }
}
.col-fotos2{
        flex: 0 0 12%;
        max-width: 12%;
        padding-left:0.5rem;
        padding-right: 0.5rem;

        @include mq("phone-wide",max){
            flex: 0 0 30%;
            max-width: 30%;
        }
}
.col-fotos3{
    flex: 0 0 25%;
    max-width: 25%;
    @include mq("phone-wide",max){
        flex: 0 0 50%;
        max-width: 50%;
    }
}