//===========================================
// -Fonts start
//===========================================

@include font-face("family", $font-path, "Roboto-Regular", 400, "normal");


/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 30, 2023 */



@font-face {
    font-family: 'aileronsregular';
    src: url('../fonts/ailerons-webfont.woff2') format('woff2'),
         url('../fonts/ailerons-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'galvjiregular';
    src: url('../fonts/galvji-webfont.woff2') format('woff2'),
         url('../fonts/galvji-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'galvjibold';
    src: url('../fonts/galvji-bold-02-webfont.woff2') format('woff2'),
         url('../fonts/galvji-bold-02-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

//===========================================
// -Fonts  end
//===========================================

