//===========================================
// -SECTIONS
//===========================================
section{

    @include mq("md",'min') {
        min-height: 100vh;
        padding: 50px 0;
    }
    width: 100%;

    @include mq("md",'max') {
        text-align: center;
    }
    h2{
        font-size: 44px;
        @include mq("md",'max') {
            font-size: 1.75em;
          }
    }
    h3{
        font-size: 19px;
        @include mq("md",'max') {
            font-size: 1.35em;
          }
    }
    h4{
        font-size: 18px;
        @include mq("md",'max') {
            font-size: 1.15em;
          }
    }
    .btnBrand{
        background: rgba(61, 53, 34, 0.996);
        border: none;
        border-radius: 0 15px 0 15px;
        display: inline-block;
        padding: 5px 40px;
        color: #fff;
        margin: 0 auto;
        &:hover{
            background: #fff;
            color: #000;
            transition: 0.2s all linear;
            text-decoration: none;
        }
    }
    .btnLight{
        background: #fff;
        border-radius: 4px;
        border: 2px solid #fff;
        display: inline-block;
        padding: 5px 40px;
        color: #000;
        &:hover{
            background: #000;
            color: #fff;
            transition: 0.2s all linear;
            text-decoration: none;
        }
    }
    &#capa{
        background: url('../images/fundo-01.webp');
        background-size: cover;
        color: #fff;
        .header{
            @include mq("md",'max') {
                flex-direction: column-reverse;
            }
        }
        .logoCasa{
            max-height: 60px;
        }
        .logoCirela{
            max-height: 40px;
            margin-bottom: 20px;
            @include mq("md",'max') {
                max-height: 30px;
            }
            @include mq("md",'min') {
            float: right;
            margin-bottom: 0px;
            }
        }
        h2{
            color: #fff;
            margin-top: 50px;
        }
        p{
            margin-bottom: 10px;
            font-size: 1.2em;
        }
        input{
            margin-bottom: 10px;
        }
    }
    &#video{
      
        text-align: center;
        h2{
            
            width: 70%;
            width: 100%;
          
        }
        h3{
            @include mq("md",'min') {
            font-size: 28px;
            }
            width: 70%;
            margin: 25px auto;
            color: rgb(133, 120, 103);
        }
        iframe{
            width: 100%;
            height: 550px; 
            @include mq("phone-wide", max){
            height: 350px;
            }
        }
    }
    &#detalhes-01{
        background: url('../images/10183801.webp');
        background-size: cover;
      .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include mq("md",'min') {
        height: 90vh;
        }
        text-align: center;
        color: #fff;
        p{
            font-size: 1.2em;
        }
      }
    }
    &#localizacao{
        background: url('../images/10184443.webp');
        background-size: cover;
      .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 90vh;
        text-align: center;
        color: #fff;
        width: 70%;
        h2{
            font-weight: normal;
        }
        p{
            font-size: 1.2em;
        }
      }
    }
   
    &#mapa{
        background: rgba(193, 178, 157, 0.996);
        color: #fff;
        p{
            font-size: 1.2em;
        }
        iframe{
            @include mq("md",'max') {
                margin: 25px 0;
            }
        }
    }
  
    &#fica-tecnica{
        background: rgba(32, 32, 32, 0.992) url( '../images/10184650-_2_.webp');
        background-size: 100%;
        background-repeat: no-repeat;
        @include mq("md",'max') {
        width: 100%;
        padding-top:90vw;
        padding-bottom: 30px;
        }
        @include mq("md",'min') {
            background-position: 50vw ;
            background-size: contain;
        }
        padding: 0;
        color: #fff;
        img{
            height: 100vh;
            width: auto;
        }
        .row{
            @include mq("md",'min') {
            height: 100vh;
            
            }
            align-items: center;
        }
        h4{
            line-height: 140%;
        }
    }
    &#CIR-FORM{
        background: url('../images/10187376.webp');
        background-size: cover;
      .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 90vh;
        text-align: center;
        color: #fff;
        @include mq("md",'min') {
            width: 70%;
        }
        h2{
            font-weight: bold;
        }
        p{
            font-size: 1.2em;
        }
        img{
            max-height: 50px;
            @include mq("md",'max') {
                margin-bottom: 20px;
            }
        }
        .row{
            width: 100%;
            text-align: center;
        }
        input{
            background: rgba(255,255,255,0.7);
            margin: 5px auto 10px auto;
            @include mq("md",'min') {
                width: 70%;
            }
            border-radius: 6px;
        }
      }
    }
    &#footer{
        height: auto;
        min-height: auto !important;
        text-align: center;
    }

    &#obrigado{
        background: url('../images/fundo-01.webp');
        background-size: cover;
        color: #fff;
        .header{
            @include mq("md",'max') {
                flex-direction: column-reverse;
            }
        }
        .logoCasa{
            max-height: 60px;
        }
        .logoCirela{
            max-height: 40px;
            margin-bottom: 20px;
            @include mq("md",'max') {
                max-height: 30px;
            }
            @include mq("md",'min') {
            float: right;
            margin-bottom: 0px;
            }
        }
        h2{
            color: #fff;
            margin-top: 100px;

        }
        p{
            margin-bottom: 10px;
            font-size: 1.2em;
        }
        input{
            margin-bottom: 10px;
        }
    }
}

//===========================================
// -SECTIONS
//===========================================
