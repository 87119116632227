#video{
    background: url('../images/BG-Branco.jpg');
    background-size: cover;
   
    .newslatter{
        background: url('../images/bg_azul2.jpg');
        background-size: cover;
        padding: 30px;
        border-radius: 20px;
        color: #fff;
        margin-top: 30px;
        margin-bottom: 2rem;
        h4{
            color: #f6dc8c;
            letter-spacing: 2px;
            font-weight: 100;
            font-family: $font-primary;
            text-transform: uppercase;
            font-size: 15px;

            @include mq("phone-wide",max){
                letter-spacing: 0px;
                line-height: 1.5;
          }
        }
        .form-group{
            background: #fff;
            border-radius: 4px;
            position: relative;
            text-align: left;
            height: 42px;
            i{
                position: absolute;
                left: 10px;
                top: 12px;
                color: #333;
                z-index: 1;
            }
            input{
                position: absolute;
                width: 100%;
                height: 100%;
                padding-left: 40px;
                z-index: 0  ;
            }
        }
    }
}

.confvideo{
    margin-top: 2rem;
}