#capa {
    position: relative;
    width: 100vw;
    height: 100vh;
    padding: 0;
    overflow: hidden;
    @include mq("tablet",max){
        height: 80vh;
    }
    #banner {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .item {
            background-size: cover !important;
            height: 100vh;
            background-position: center !important;

        }

        .arrow {
            top: 39%;
            position: absolute;
            background: rgba(#313d4a, 0.7);
            color: #fff;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 0;

            i {
                margin-top: 4px;
            }

            &.prev {
                left: 40px;
                z-index: 100;
                @include mq("tablet",max){
                    left: 20px;
                }
            }

            &.next {
                right: 40px;
                z-index: 100;
                @include mq("tablet",max){
                    right: 20px;
                } 
            }
        }
    }

    .controleChamada {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 50px;
        left: 0;
        @include mq("tablet",max){
            width: 60%;
            padding: 0;
            left: 20%;
        }
        .logo {
            max-width: 345px;

            @include mq("phone-wide", max) {
                max-width: 200px;
                margin-top: 60px;
                margin-bottom: 60px;
            }
        }

        .text {
            text-align: center;

            img {
                margin: 20px 0;
                max-width: 100%;
            }

            h4 {
                font-weight: 100;
                font-family: $font-default;
                letter-spacing: 2px;
            }
        }

        .destaque {
            background: rgba(#313d4a, 0.5);
            width: fit-content;
            padding: 10px 15px;
            border-radius: 25px;
            margin: 0 auto;
            letter-spacing: 3px;
            font-weight: 100;

            @include mq("phone-wide", max) {
                letter-spacing: 0;
                font-size: 13px;
            }
        }
    }
}