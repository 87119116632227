#primeiro-residencial {
    .container {
        &::before {
            content: "";
            width: 10%;
            height: 50%;
            display: block;
            position: absolute;
            border: 1px solid #b79345;
            border-right: none;
            border-bottom: none;
            border-radius: 5px 0px 5px 0px;
            margin-left: 17px;
            margin-top: 25px;

        }
    }
}

#estilo-vida {
    background: url('../images/BG-Branco.jpg');
    background-size: cover;

    .container {
        #estilo-vida-logo {
            
            @include mq("phone-wide", max) {
                display: flex;
            }
        }
    }

    h2 {
        font-family: $font-default;
        color: #84754f;
        font-size: 3.5em;
        margin-bottom: 50px;
        margin-top: 50px;
        width: 70%;
        font-weight: 100;
        letter-spacing: 0px;

        @include mq("phone-wide", max) {
            font-size: 1.5em;
        }
    }

    .accordion {
        background: none;
        border: none;

        .card-header {
            background: #132a3e;
            color: #fff;

            border-radius: 10px;

            .btn-link {
                color: #fff;

                &:focus {
                    box-shadow: none;
                }

                &:hover {
                    text-decoration: none;
                }

                ;
            }

            .icon-plus {
                background: url(../images/icon-minus-02.svg);
                background-repeat: no-repeat;
                background-position: right center;
                background-repeat: no-repeat;
                background-size: 33px;
                transition: 0.2s all linear;

                &.collapsed {
                    background: url(../images/icon-plus-01.svg);
                    background-repeat: no-repeat;
                    background-position: right center;
                    background-repeat: no-repeat;
                    background-size: 33px;
                    transition: 0.2s all linear;

                }
            }
        }

        .card-body {
            background: #0b1f30;
            color: #fff;
            padding: 35px;
            margin: 15px 0;
            border-radius: 10px;
        }
    }

    
}
.construcao {
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
        @include mq("phone-wide",max) {
            font-size: 8px;
        }
    }

.col-estilodevida{
        flex: 0 0 5%;
        max-width: 5%;
        margin-bottom: 4rem;
        border-radius: 0px 5px 0px 5px;
        border-left: 1px solid #84754f;
        border-bottom: 1px solid #84754f;
}