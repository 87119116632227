//===========================================
// -Helper Classes start
//===========================================

.center {
  margin: auto;
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

//===========================================
// -Helper Classes end
//===========================================
