#residencial-green{
    background:url('../images/BG-Branco.jpg');
    background-size: cover;
    min-height: auto;
    h2{
        font-family: $font-default;
        color: #202b3c;
        font-size: 2.9em;

        @include mq("phone-wide", max) {
            font-size: 1.5em;
            text-align: center;
        }
    }
    p{
        font-family: $font-primary;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .selo{
        height: 175px;
        @include mq("phone-wide",max) {
            height: 75px;
            margin-top: 70px;
        }
    }
    span{
        background: #202b3c;
        color: #fff;
        display: inline-block;
        padding: 3px 5px;
        border-radius: 4px;
        @include mq("phone-wide",max){
            font-size: 8px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
    ul{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        gap: 10px;
        @include mq("phone-wide",max) {
            font-size: 12px;
            gap: 1;
            width: 100%;
            margin: 0 auto;
            flex-wrap: wrap;
          }

        li{
            list-style: none;
            display: inline-block;
            display: flex;
            border-top: 1px solid #b79345;
            border-right: 1px solid #b79345;
            border-radius: 0px 5px 0px 5px;
            padding:  10px;
            align-items: center;

            img{
                width: 25px;
            }
        }
    }
}

.col-residencial-mobile{
    flex: 0 0 25%;
    max-width: 25%;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    @include mq("phone-wide",max){
        flex: 0 0 30%;
        max-width: 30%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-right: 2rem;
    }
}

.col-logo-mobile{
    @include mq("phone-wide",max){
        margin-left: calc(50% - 25%);
    }
}