#nav-icon2{
    position: fixed;
    left: 30px;
    top: 30px;
}
#menu{
    position: fixed;
    left: -250px;
    top: 80px;
    z-index: 999999;
    ul{
        margin: 0;
        padding: 0;
        li{
            list-style: none;
            border: 2px solid rgba(#b89446,0.2);
            background: rgba(#253242, 0.2);
            &:hover{
                border: 2px solid rgba(#b89446,1);
                background: rgba(#253242,1);
                transition: 0.2s all linear;
            }
            a{
                display: block;
                color: #fff;
                padding: 5px 15px;
                font-size: 0.9em;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}