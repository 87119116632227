//===========================================
// -Buttons start
//===========================================

.btn {
  cursor: pointer;
  &[disabled],
  &.disabled {
    cursor: not-allowed;
  }
}
.btn-brand{
  background: #bb9447;
  color: #112c3f;
}
//===========================================
// -Buttons end
//===========================================
   