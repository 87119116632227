#paisagismo {
    background: url(../images/bg_azul2.jpg);
    background-size: cover;
    padding-bottom: 0;

    h2 {
        color: #b79345;
        font-family: $font-default;
        font-size: 3em;
        font-weight: 100;
        padding-top: 20px;

        @include mq("phone-wide", max) {
            font-size: 25px;
        }
    }

    color: #fff;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 2px;

    iframe{
        width: 100%;
        height: 100%; 
        @include mq("phone-wide", max){
        height: 350px;
        }
    }

    .logo-depieri {
        max-height: 150px;
    }

   
        .logo-palazzo {
            max-width: 100px;
            @include mq("desktop", min) {
                display: none;
            }
        
    }
}

.col-paisagismo {
    flex: 0 0 3%;
    max-width: 3%;
    margin-top: 5.5rem;
    border-left: 1px solid #b79345;
    border-top: 1px solid #b79345;
    border-radius: 5px 0px 5px 0px;
}

#paisagismo-mobile {
    background: url(../images/bg_azul2.jpg);
    background-size: cover;
}

.col-mobile{
    padding-top: 8rem;
    padding-bottom: 8rem;
}