#decoracao{
    background: url(../images/BG-DOURADO-2.jpg);
    padding: 0;
    color: #1f2b3c;

    @include mq("phone-wide",max) {
        padding-top: 40px;
    }
    
    h3{
        font-family: $font-default;
        color: #1f2b3c;
        font-size: 44px;
        font-weight: 100;

        @include mq("phone-wide",max){
            font-size: 20px;
      }
    }
    p{
        letter-spacing: 2px;
    }
   span{
    border: 1px solid #1f2b3c;
    border-radius: 4px;
    display: inline-block;
    padding: 3px;
   }
}

.p-mobile{
    @include mq("phone-wide",max){
        font-size: 14px;
        letter-spacing: 0;
    }
}