#fale-conosco{
    background: url('../images/bg_azul2.jpg');
    background-size: cover;
   padding-bottom: 0;

    .formulario{
        background:url('../images/BG-Branco.jpg');
        background-size: cover;
        padding-bottom: 0;
        padding: 50px;
    }
    .newslatter{
       
        padding: 30px;
        border-radius: 20px;
        color: #fff;
        margin-top: 30px;
        background: rgba(0,0,0,0.32);
        h4{
            color: #f6dc8c;
            letter-spacing: 2px;
            font-weight: 100;
            font-family: $font-primary;
            text-transform: uppercase;
            font-size: 15px;
        }
        .form-group{
            background: #fff;
            border-radius: 4px;
            position: relative;
            text-align: left;
            height: 42px;
            i{
                position: absolute;
                left: 10px;
                top: 12px;
                color: #333;
                z-index: 1;
            }
            input{
                position: absolute;
                width: 100%;
                height: 100%;
                padding-left: 40px;
                z-index: 0  ;
            }
        }
    }
}
.fale-conosco-fixed{
    position: fixed;
    bottom: 0;
    right: 50px;
    background: #b79345;
    border-radius: 4px 4px 0 0 ;
    a{
        color: #fff;
      
        display: block;
        padding: 5px 10px;
        &:hover{
            text-decoration: none;
        }
    }
}