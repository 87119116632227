
    .galeria{
        height: 90vh;
        margin: 0;
        .arrow{
            top: 45%;
            position: absolute;
            background: rgba(#313d4a,0.7);
            color: #fff;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 0;
            i{
                margin-top: 4px;
            }
            &.prev{
                left: 40px;
                z-index: 100;
            }
            &.next{
                right: 40px;
                z-index: 100;
            }
        }

        img{
            height: 90vh;
            width: 100vw;
            object-fit: cover;
            object-position:center;
        }
    }
